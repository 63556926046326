import { IMAGE, SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const CompetitionFields = [
    {
        name: "eventTypeId",
        label: "Event Type",
        isRequired: true,
        parentclassName: "",
        type: SELECT,
        options: [{ label: "Select Event Type", value: "0" }],
        defaultValue: "0",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 }
    },
    {
        name: "competition",
        isRequired: true,
        label: "Competition",
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        parentclassName: "",
        type: TEXT,
    },
    {
        name: "refId",
        isRequired: true,
        label: "Reference Id",
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        parentclassName: "",
        type: TEXT,
    },
    {
        name: "isTrending",
        label: "Is Trending",
        defaultValue: false,
        parentclassName: "",
        type: SWITCH,
    },
    {
        name: "isEventSnap",
        label: "Is Event Snap",
        defaultValue: false,
        parentclassName: "",
        type: SWITCH,
    },
    {
        name: "isPointTable",
        label: "Is Point Table",
        defaultValue: false,
        parentclassName: "",
        type: SWITCH,
    },
    {
        type: TEXT,
        name: "winPoint",
        label: "Win Point",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: TEXT,
        name: "tiePoint",
        label: "Tie Point",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: TEXT,
        name: "lossPoint",
        label: "Loss Point",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: TEXT,
        name: "cancelPoint",
        label: "Cancel Point",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        name: "image",
        label: "Event Image",
        parentclassName: "",
        type: IMAGE,
    },
    {
        name: "isActive",
        label: "Is Active",
        defaultValue: true,
        parentclassName: "",
        type: SWITCH,
    },
]